@import "~@fontsource/press-start-2p/index.css";
@import "~@16bits/nes.css/css/nes.min.css";

*{
  font-family: PixelFont-7,monospace;
}

.nes-btn {
  font-family: "Press Start 2P";
}
