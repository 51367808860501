//TextGlobalMessage

// TODO: load on demand with TextGlobalMessage component
@import "quill/dist/quill.snow.css";

section.section-input-send-text {
  --height-toolbar: 20%;
  height: 100%;

  .ql-toolbar{
    max-height: var(--height-toolbar);
    background: whitesmoke;
  }

  div.input-send-text{
    height: calc(100% - var(--height-toolbar));
    overflow: auto;

    color: whitesmoke;
    font-size: 1rem;

    .ql-editor.ql-blank::before {
      color: whitesmoke;
      font-size: 1rem;
    }

    .ql-tooltip {
      top: 40% !important;
      left: 20% !important;

      color: whitesmoke;
      background-color: #333333;
    }
  }
}

@include media-breakpoint-up(md) {
  section.section-input-send-text {
    --height-toolbar: 30%;

    .ql-toolbar {
      overflow: auto;
    }
  }
}
